import React, { useState, useEffect } from "react";
import { keywordSearchApi } from "../api";
import Loader from "./Loader";
import styled from "styled-components";
import MaterialTable from "material-table";
import DatePick from "./DatePick";
import Graph from "./Graph";
import DetailInfo from "./DetailInfo";
import DetailInfoWeb from "./DetailInfoWeb";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import Tooltip from "@material-ui/core/Tooltip";
import Helmet from "react-helmet";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { ContinuousColorLegend } from "react-vis";

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1rem",
      },
    },
  },
});

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const FindKeyword = (date, apiKey, type) => {
  const [keywords, setKeywords] = useState({ columns: [], data: [] });
  const [loading, setLoading] = useState(false);
  const [keywordCount, setkeywordCount] = useState(0);
  const [error, setError] = useState(null);

  async function findKeyword() {
    try {
      setLoading(true);
      let response;
      switch (type) {
        case "list":
          response = await keywordSearchApi.findKeyword(date, apiKey);
          console.log("List type response:", {
            type: typeof response.data,
            isArray: Array.isArray(response.data),
            hasItems: response.data?.Items,
            raw: response.data
          });
          break;
        case "common":
          response = await keywordSearchApi.findCommonKeyword(date, apiKey);
          console.log("Common type response:", {
            type: typeof response.data,
            isArray: Array.isArray(response.data),
            hasItems: response.data?.Items,
            raw: response.data
          });
          break;
        case "product":
          response = await keywordSearchApi.findProductKeyword(date, apiKey);
          console.log("Product type response:", {
            type: typeof response.data,
            isArray: Array.isArray(response.data),
            hasItems: response.data?.Items,
            raw: response.data
          });
          break;
        case "sublist":
          response = await keywordSearchApi.findSubKeyword(date, apiKey);
          console.log("Sublist type response:", {
            type: typeof response.data,
            isArray: Array.isArray(response.data),
            hasItems: response.data?.Items,
            raw: response.data
          });
          break;
        default:
          throw new Error("Invalid type provided");
      }

      const { data: responseData } = response;
      console.log("Response data:", responseData);

      let keywords = [], count = 0;
      
      if (!responseData) {
        throw new Error("No response data received");
      }

      if (type === "list") {
        if (Array.isArray(responseData)) {
          keywords = responseData;
          count = responseData.length;
        } else if (responseData.Items && Array.isArray(responseData.Items)) {
          keywords = responseData.Items;
          count = responseData.Count || responseData.Items.length;
        } else {
          throw new Error("Invalid response format for list type");
        }
      } else {
        if (!responseData.Items || !Array.isArray(responseData.Items)) {
          throw new Error("Invalid response format");
        }
        keywords = responseData.Items;
        count = responseData.Count || responseData.Items.length;
      }

      if (!keywords || keywords.length === 0) {
        setKeywords({ columns: [], data: [] });
        setkeywordCount(0);
        return;
      }

      const columns = [
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="5분 주기로 업데이트 되는 키워드 리스트(클릭 시 서브키워드 검색)"
                placement="top"
              >
                <span>키워드</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "keyword",
          cellStyle: {
            width: 300,
            maxWidth: 300,
            fontSize: "10.3pt",
          },
          render: (rowData) => (
            <Link
              to={`/search/${encodeURI(rowData.keyword)}`}
              target="_blank"
              style={{ color: "rgb(1, 87, 155)", fontWeight: "1000" }}
            >
              {rowData.keyword}
            </Link>
          ),
        },
        // {
        //   title: (
        //     <MuiThemeProvider theme={theme}>
        //       <Tooltip
        //         title="티스토리 블로그 개수(클릭 시 다음 모바일 검색)"
        //         placement="top"
        //       >
        //         <span>T블로그</span>
        //       </Tooltip>
        //     </MuiThemeProvider>
        //   ),
        //   render: (rowData) => (
        //     <a
        //       href={`https://m.search.daum.net/search?w=tot&nil_mtopsearch=btn&DA=YZR&q=${rowData.keyword}`}
        //       target="_blank"
        //       style={{ color: "rgb(1, 87, 155)" }}
        //     >
        //       {rowData.daum_blog_count}
        //     </a>
        //   ),
        //   field: "daum_blog_count",
        //   cellStyle: {
        //     textAlign: "center",
        //     width: 1,
        //     maxWidth: 1,
        //     fontSize: "10.3pt",
        //   },
        // },
        // {
        //   title: (
        //     <MuiThemeProvider theme={theme}>
        //       <Tooltip title="최근 한 달 티스토리 블로그 개수" placement="top">
        //         <span>T블로그(Month)</span>
        //       </Tooltip>
        //     </MuiThemeProvider>
        //   ),
        //   field: "mdaum_blog_count",
        //   cellStyle: {
        //     textAlign: "center",
        //     width: 1,
        //     maxWidth: 1,
        //     fontSize: "10.3pt",
        //   },
        // },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="네이버 블로그 개수(클릭 시 네이버 모바일 검색)"
                placement="top"
              >
                <span>N블로그</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          render: (rowData) => (
            <a
              href={`https://m.search.naver.com/search.naver?ssc=tab.m_blog.all&sm=mtb_jum&query=${rowData.keyword}`}
              target="_blank"
              style={{ color: "rgb(1, 87, 155)" }}
            >
              {rowData.naver_blog_count}
            </a>
          ),
          field: "naver_blog_count",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip title="한 달 네이버 블로그 발행 개수" placement="top">
                <span>N블로그(Month)</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          render: (rowData) => (
            <a
              href={`https://m.search.naver.com/search.naver?ssc=tab.m_blog.all&sm=mtb_jum&query=${rowData.keyword}&nso=so%3Ar%2Cp%3A1m`}
              target="_blank"
              style={{ color: "rgb(1, 87, 155)" }}
            >
              {rowData.mnaver_blog_count}
            </a>
          ),
          field: "mnaver_blog_count",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="쇼핑몰 개수(클릭 시 네이버 모바일 쇼핑 검색)"
                placement="top"
              >
                <span>쇼핑몰</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          render: (rowData) => (
            <a
              href={`https://msearch.shopping.naver.com/search/all?query=${rowData.keyword}`}
              target="_blank"
              style={{ color: "rgb(1, 87, 155)" }}
            >
              {rowData.nshop}
            </a>
          ),
          field: "nshop",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="한 달 동안 네이버, 다음, 구글 PC 추정 검색량"
                placement="top-start"
              >
                <span>PC검색(Month)</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "pc_search",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="한 달 동안 네이버, 다음, 구글 모바일 추정 검색량"
                placement="top-start"
              >
                <span>M검색(Month)</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "m_search",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="검색량 대비 블로그 개수 비율(높을수록 유입 확률이 높음)"
                placement="top-start"
              >
                <span>B지수</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "blog_rate",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="검색량 대비 한 달 블로그 발행 비율(높을수록 유입 확률이 높음)"
                placement="top-start"
              >
                <span>B지수(Month)</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "mblog_rate",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="검색량 대비 쇼핑몰 개수 비율(높을수록 유입 확률이 높음)"
                placement="top-start"
              >
                <span>S지수</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "s_rate",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip title="네이버 모바일 노출" placement="top-start">
                <span>N 모바일 노출</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "blog_order",
          cellStyle: {
            textAlign: "center",
            width: 250,
            maxWidth: 250,
            fontSize: "10.3pt",
          },
          render: (rowData) => (
            <a
              href={`https://m.search.naver.com/search.naver?sm=mtp_hty.top&where=m&query=${rowData.keyword}`}
              target="_blank"
              style={{ color: "rgb(1, 87, 155)" }}
            >
              {rowData.blog_order}
            </a>
          ),
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip title="다음 모바일 노출" placement="top-start">
                <span>D 모바일 노출</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "dblog_order",
          cellStyle: {
            textAlign: "center",
            width: 250,
            maxWidth: 250,
            fontSize: "10.3pt",
          },
          render: (rowData) => (
            <a
              href={`https://m.search.daum.net/search?w=tot&nil_mtopsearch=btn&DA=YZR&q=${rowData.keyword}`}
              target="_blank"
              style={{ color: "rgb(1, 87, 155)" }}
            >
              {rowData.dblog_order}
            </a>
          ),
        },
        
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="문맥 광고 노출 점수(A가 C보다 높음)"
                placement="top-start"
              >
                <span>광고</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "ad_rate",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="광고 입찰 시 필요한 최대 광고 단가"
                placement="top-start"
              >
                <span>광고비</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "ad_estimate",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip title="노출되는 광고의 클릭률" placement="top-start">
                <span>클릭률</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "ad_ctr",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
      ];
      
      const tableData = [];

      keywords.forEach((keyword) => {
        if (!keyword) return;

        const naver_order = [];
        const daum_order = [];
        
        try {
          // blog_order 처리
          if (keyword.blog_order?.L) {
            keyword.blog_order.L.forEach((order, index) => {
              if (order?.S) {
                naver_order.push(`[${index + 1}.${order.S}] `);
              }
            });
          } else if (Array.isArray(keyword.blog_order)) {
            // blog_order가 직접 배열인 경우
            keyword.blog_order.forEach((order, index) => {
              if (typeof order === 'string') {
                naver_order.push(`[${index + 1}.${order}] `);
              } else if (order?.S) {
                naver_order.push(`[${index + 1}.${order.S}] `);
              }
            });
          }
          
          // dblog_order 처리
          if (keyword.dblog_order?.L) {
            keyword.dblog_order.L.forEach((order, index) => {
              if (order?.S) {
                daum_order.push(`[${index + 1}.${order.S}] `);
              }
            });
          } else if (Array.isArray(keyword.dblog_order)) {
            // dblog_order가 직접 배열인 경우
            keyword.dblog_order.forEach((order, index) => {
              if (typeof order === 'string') {
                daum_order.push(`[${index + 1}.${order}] `);
              } else if (order?.S) {
                daum_order.push(`[${index + 1}.${order.S}] `);
              }
            });
          }

          tableData.push({
            keyword: typeof keyword.keyword === 'string' ? keyword.keyword : keyword.keyword?.S || '',
            daum_blog_count: Number(keyword.daum_blog_count?.N) || 0,
            mdaum_blog_count: Number(keyword.mdaum_blog_count?.N) || 0,
            naver_blog_count: Number(keyword.naver_blog_count?.N) || 0,
            mnaver_blog_count: Number(keyword.mnaver_blog_count?.N) || 0,
            nshop: Number(keyword.nshop?.N) || 0,
            pc_search: Number(keyword.pc_search?.N) || 0,
            m_search: Number(keyword.m_search?.N) || 0,
            blog_rate: Number(keyword.blog_rate?.N) || 0,
            mblog_rate: Number(keyword.mblog_rate?.N) || 0,
            s_rate: Number(keyword.s_rate?.N) || 0,
            blog_order: naver_order.join(''),  // join으로 변경
            dblog_order: daum_order.join(''),  // join으로 변경
            ad_rate: keyword.ad_rate?.S || '',
            ad_estimate: Number(keyword.ad_estimate?.N) || 0,
            ad_ctr: Number(keyword.ad_ctr?.N) || 0,
          });
        } catch (err) {
          console.error('Error processing keyword:', keyword, err);
          // 개별 키워드 처리 실패 시 해당 키워드는 건너뛰고 계속 진행
        }
      });
      
      setKeywords({ columns, data: tableData });
      setkeywordCount(count);
    } catch (err) {
      console.error("Keyword search error:", err);
      console.error("Error details:", {
        type,
        response: err.response,
        message: err.message,
        stack: err.stack
      });
      toast.error(
        <div>
          {err.response?.status === 429 
            ? "일 요청수를 초과했습니다. 키워드가 업데이트 되지 않습니다."
            : "키워드 검색 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요."}
        </div>,
        {
          autoClose: 5000,
        }
      );
      setError(err.message || "Can't Find Keywords");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    findKeyword();
  }, [date]);

  return { keywords, loading, keywordCount };
};

const Table = ({ type }) => {
  const cookie = new Cookies({});
  const apiKey = cookie.get("apiKeyValue");
  const tier = cookie.get("tier");
  const { date, Selector } = DatePick();
  const { keywords, loading, keywordCount } = FindKeyword(date, apiKey, type);
  const frontDate = date.split("-");
  return loading ? (
    <Container>
      <Selector />
      <Loader />
    </Container>
  ) : (
    <Container>
      <Helmet>
        <title>황금키워드</title>
      </Helmet>
      <Selector />
      <MaterialTable
        title={`[${frontDate[2]}-${frontDate[1]}-${frontDate[0]}] 황금 키워드 ${keywordCount}개 발견`}
        columns={keywords.columns}
        data={keywords.data}
        detailPanel={(rowData) => {
          return (
            <>
              <DetailInfo keyword={rowData.keyword} />
              <DetailInfoWeb keyword={rowData.keyword} />
              <Graph
                keyword={rowData.keyword}
                search={rowData.pc_search + rowData.m_search}
              />
            </>
          );
        }}
        options={{
          pageSize: 300,
          // exportButton: false,
          exportButton: tier === "Pro" ? true : false,
          maxBodyHeight: 640,
          minBodyHeight: 150,
          pageSizeOptions: [300, 500],
          headerStyle: {
            position: "sticky",
            top: 0,
            backgroundColor: "#01579b",
            color: "#FAFAFA",
            fontSize: "10.8pt",
            fontWeight: "bold",
            padding: "3px",
            textAlign: "center",
          },
        }}
      />
    </Container>
  );
};

export default withCookies(Table);
