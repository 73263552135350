import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { keywordSearchApi } from "api";
import Loader from "Components/Loader";
import styled from "styled-components";
import MaterialTable from "material-table";
import Helmet from "react-helmet";
import { useForm } from "react-hook-form";
import Graph from "Components/Graph";
import DetailInfo from "Components/DetailInfo";
import DetailInfoWeb from "Components/DetailInfoWeb";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { withCookies, Cookies } from "react-cookie";
import Tooltip from "@material-ui/core/Tooltip";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1rem",
      },
    },
  },
});

const Container = styled.div`
  width: 100%;
`;

const Form = styled.form`
  margin: 0.5% 0.5% 0.5% 0.5%;
  width: 80%;
`;

const Input = styled.input`
  all: unset;
  font-size: 28px;
  width: 100%;
`;

const SearchKeyword = (search_keyword, apiKey) => {
  const [keywords, setKeywords] = useState({ columns: [], data: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  async function searchKeyword() {
    try {
      const columns = [
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="5분 주기로 업데이트 되는 키워드 리스트(클릭 시 서브키워드 검색)"
                placement="top"
              >
                <span>키워드</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "keyword",
          cellStyle: {
            width: 300,
            maxWidth: 300,
            fontSize: "10.3pt",
          },
          render: (rowData) => (
            <Link
              to={`/search/${encodeURI(rowData.keyword)}`}
              target="_blank"
              style={{ color: "rgb(1, 87, 155)", fontWeight: "1000" }}
            >
              {rowData.keyword}
            </Link>
          ),
        },
        // {
        //   title: (
        //     <MuiThemeProvider theme={theme}>
        //       <Tooltip
        //         title="티스토리 블로그 개수(클릭 시 다음 모바일 검색)"
        //         placement="top"
        //       >
        //         <span>T블로그</span>
        //       </Tooltip>
        //     </MuiThemeProvider>
        //   ),
        //   render: (rowData) => (
        //     <a
        //       href={`https://m.search.daum.net/search?w=tot&nil_mtopsearch=btn&DA=YZR&q=${rowData.keyword}`}
        //       target="_blank"
        //       style={{ color: "rgb(1, 87, 155)" }}
        //     >
        //       {rowData.daum_blog_count}
        //     </a>
        //   ),
        //   field: "daum_blog_count",
        //   cellStyle: {
        //     textAlign: "center",
        //     width: 1,
        //     maxWidth: 1,
        //     fontSize: "10.3pt",
        //   },
        // },
        // {
        //   title: (
        //     <MuiThemeProvider theme={theme}>
        //       <Tooltip title="최근 한 달 티스토리 블로그 개수" placement="top">
        //         <span>T블로그(Month)</span>
        //       </Tooltip>
        //     </MuiThemeProvider>
        //   ),
        //   field: "mdaum_blog_count",
        //   cellStyle: {
        //     textAlign: "center",
        //     width: 1,
        //     maxWidth: 1,
        //     fontSize: "10.3pt",
        //   },
        // },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="네이버 블로그 개수(클릭 시 네이버 모바일 검색)"
                placement="top"
              >
                <span>N블로그</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          render: (rowData) => (
            <a
              href={`https://m.search.naver.com/search.naver?ssc=tab.m_blog.all&sm=mtb_jum&query=${rowData.keyword}`}
              target="_blank"
              style={{ color: "rgb(1, 87, 155)" }}
            >
              {rowData.naver_blog_count}
            </a>
          ),
          field: "naver_blog_count",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip title="한 달 네이버 블로그 발행 개수" placement="top">
                <span>N블로그(Month)</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          render: (rowData) => (
            <a
              href={`https://m.search.naver.com/search.naver?ssc=tab.m_blog.all&sm=mtb_jum&query=${rowData.keyword}&nso=so%3Ar%2Cp%3A1m`}
              target="_blank"
              style={{ color: "rgb(1, 87, 155)" }}
            >
              {rowData.mnaver_blog_count}
            </a>
          ),
          field: "mnaver_blog_count",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="쇼핑몰 개수(클릭 시 네이버 모바일 쇼핑 검색)"
                placement="top"
              >
                <span>쇼핑몰</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          render: (rowData) => (
            <a
              href={`https://msearch.shopping.naver.com/search/all?query=${rowData.keyword}`}
              target="_blank"
              style={{ color: "rgb(1, 87, 155)" }}
            >
              {rowData.nshop}
            </a>
          ),
          field: "nshop",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="한 달 동안 네이버, 다음, 구글 PC 추정 검색량"
                placement="top-start"
              >
                <span>PC검색(Month)</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "pc_search",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="한 달 동안 네이버, 다음, 구글 모바일 추정 검색량"
                placement="top-start"
              >
                <span>M검색(Month)</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "m_search",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="검색량 대비 블로그 개수 비율(높을수록 유입 확률이 높음)"
                placement="top-start"
              >
                <span>B지수</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "blog_rate",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="검색량 대비 한 달 블로그 발행 비율(높을수록 유입 확률이 높음)"
                placement="top-start"
              >
                <span>B지수(Month)</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "mblog_rate",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="검색량 대비 쇼핑몰 개수 비율(높을수록 유입 확률이 높음)"
                placement="top-start"
              >
                <span>S지수</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "s_rate",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip title="네이버 모바일 노출" placement="top-start">
                <span>N 모바일 노출</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "blog_order",
          cellStyle: {
            textAlign: "center",
            width: 250,
            maxWidth: 250,
            fontSize: "10.3pt",
          },
          render: (rowData) => (
            <a
              href={`https://m.search.naver.com/search.naver?sm=mtp_hty.top&where=m&query=${rowData.keyword}`}
              target="_blank"
              style={{ color: "rgb(1, 87, 155)" }}
            >
              {rowData.blog_order}
            </a>
          ),
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip title="다음 모바일 노출" placement="top-start">
                <span>D 모바일 노출</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "dblog_order",
          cellStyle: {
            textAlign: "center",
            width: 250,
            maxWidth: 250,
            fontSize: "10.3pt",
          },
          render: (rowData) => (
            <a
              href={`https://m.search.daum.net/search?w=tot&nil_mtopsearch=btn&DA=YZR&q=${rowData.keyword}`}
              target="_blank"
              style={{ color: "rgb(1, 87, 155)" }}
            >
              {rowData.dblog_order}
            </a>
          ),
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="문맥 광고 노출 점수(A가 C보다 높음)"
                placement="top-start"
              >
                <span>광고</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "ad_rate",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title="광고 입찰 시 필요한 최대 광고 단가"
                placement="top-start"
              >
                <span>광고비</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "ad_estimate",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
        {
          title: (
            <MuiThemeProvider theme={theme}>
              <Tooltip title="노출되는 광고의 클릭률" placement="top-start">
                <span>클릭률</span>
              </Tooltip>
            </MuiThemeProvider>
          ),
          field: "ad_ctr",
          cellStyle: {
            textAlign: "center",
            width: 1,
            maxWidth: 1,
            fontSize: "10.3pt",
          },
        },
      ];
      
      const data = [];
      
      if (search_keyword) {
        setLoading(true);
        const response = await keywordSearchApi.searchKeyword(search_keyword, apiKey);
        console.log("Search keyword response:", {
          type: typeof response.data,
          isArray: Array.isArray(response.data),
          hasBody: response.data?.body,
          isBodyArray: Array.isArray(response.data?.body),
          raw: response.data
        });

        const {
          data: { body: keywords },
        } = response;

        if (!keywords || !Array.isArray(keywords)) {
          throw new Error("Invalid response format");
        }

        keywords.forEach((keyword) => {
          if (!keyword) return;

          try {
            const naver_order = [];
            const daum_order = [];
            
            // blog_order 처리
            if (Array.isArray(keyword.blog_order)) {
              keyword.blog_order.forEach((order, index) => {
                if (typeof order === 'string') {
                  naver_order.push(`[${index + 1}.${order}] `);
                } else if (order?.S) {
                  naver_order.push(`[${index + 1}.${order.S}] `);
                }
              });
            }

            // dblog_order 처리
            if (Array.isArray(keyword.dblog_order)) {
              keyword.dblog_order.forEach((order, index) => {
                if (typeof order === 'string') {
                  daum_order.push(`[${index + 1}.${order}] `);
                } else if (order?.S) {
                  daum_order.push(`[${index + 1}.${order.S}] `);
                }
              });
            }

            data.push({
              keyword: typeof keyword.keyword === 'string' ? keyword.keyword : keyword.keyword?.S || '',
              daum_blog_count: Number(keyword.daum_blog_count) || 0,
              mdaum_blog_count: Number(keyword.mdaum_blog_count) || 0,
              naver_blog_count: Number(keyword.naver_blog_count) || 0,
              mnaver_blog_count: Number(keyword.mnaver_blog_count) || 0,
              nshop: Number(keyword.nshop) || 0,
              pc_search: Number(keyword.pc_search) || 0,
              m_search: Number(keyword.m_search) || 0,
              blog_rate: Number(keyword.blog_rate) || 0,
              mblog_rate: Number(keyword.mblog_rate) || 0,
              s_rate: Number(keyword.s_rate) || 0,
              blog_order: naver_order.join(''),
              dblog_order: daum_order.join(''),
              ad_rate: String(keyword.ad_rate || ''),
              ad_estimate: Number(keyword.ad_estimate) || 0,
              ad_ctr: Number(keyword.ad_ctr) || 0,
            });
          } catch (err) {
            console.error('Error processing keyword:', keyword, err);
          }
        });
      }

      setKeywords({ columns, data });
    } catch (err) {
      console.error("Search keyword error:", err);
      toast.error(
        <div>
          {err.response?.status === 429 
            ? "일 요청수를 초과했습니다. 키워드가 업데이트 되지 않습니다."
            : "키워드 검색 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요."}
        </div>,
        {
          autoClose: 5000,
        }
      );
      setError(err.message || "Can't Find Keywords");
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    searchKeyword();
  }, [search_keyword]);

  return { keywords, loading };
};

const SearchPresenter = ({ to }) => {
  const cookie = new Cookies({});
  const apiKey = cookie.get("apiKeyValue");
  const tier = cookie.get("tier");
  const path = useRouteMatch(to);
  let uri = "";
  if (path.params.keyword) {
    uri = path.params.keyword;
  }
  const [keyword, setKeyword] = useState(uri);
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    setKeyword(data.keyword);
  };
  const { keywords, loading } = SearchKeyword(keyword, apiKey);
  return loading ? (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="keyword"
          type="text"
          placeholder="키워드를 입력해주세요"
          defaultValue={keyword}
          ref={register}
        />
      </Form>
      <Loader />
    </Container>
  ) : (
    <Container>
      <Helmet>
        <title>황금키워드</title>
      </Helmet>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="keyword"
          type="text"
          placeholder=">>> 이곳에 검색할 키워드 입력 후 엔터 <<<"
          defaultValue={keyword}
          ref={register}
        />
      </Form>
      <MaterialTable
        title={`[${keyword}] 관련 키워드 ${keywords.data.length}개 발견`}
        columns={keywords.columns}
        data={keywords.data}
        detailPanel={(rowData) => {
          return (
            <>
              <DetailInfo keyword={rowData.keyword} />
              <DetailInfoWeb keyword={rowData.keyword} />
              <Graph
                keyword={rowData.keyword}
                search={rowData.pc_search + rowData.m_search}
              />
            </>
          );
        }}
        options={{
          pageSize: 50,
          exportButton: tier === "Pro" ? true : false,
          maxBodyHeight: 640,
          minBodyHeight: 150,
          pageSizeOptions: [50, 100],
          headerStyle: {
            position: "sticky",
            top: 0,
            backgroundColor: "#01579b",
            color: "#FAFAFA",
            fontSize: "10.8pt",
            fontWeight: "bold",
            padding: "3px",
            textAlign: "center",
          },
        }}
      />
    </Container>
  );
};

export default withCookies(SearchPresenter);
